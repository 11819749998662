import React from 'react';
import 'antd/dist/antd.css';
import './style/base.scss';
import './style/App.scss';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import loadable from './utils/AsyncLoadable'
import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import quillEmoji from 'quill-emoji';
import "quill-emoji/dist/quill-emoji.css"; //这个不引入的话会出现emoji框一直在输入框下面的情况
// import { ImageDrop } from './plugin/quill-image-drop-module'; //讲图片拖进文本框，可以直接安装quill-image-drop-module；但由于我的webpack版本过低，无法兼容es6，所以把插件拿出来了
//注册ToolbarEmoji，将在工具栏出现emoji；注册TextAreaEmoji，将在文本输入框处出现emoji。VideoBlot是我自定义的视频组件，后面会讲，

import {VideoBlot} from '@components/QuillMediaEmbed/QuillMediaEmbed'
const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;
Quill.register({
  'formats/emoji': EmojiBlot,
  'formats/video': VideoBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji,
  // 'modules/ImageExtend': ImageExtend, //拖拽图片扩展组件
  // 'modules/ImageDrop': ImageDrop, //复制粘贴组件
}, true);

const DefaultLayout = loadable(() => import(/* webpackChunkName: 'default' */ './containers'))

const Login = loadable(() => import(/* webpackChunkName: 'login' */ './views/Login'))

const App = () => (
  <Router>
    <Switch>
      <Route path='/' exact render={() => <Redirect to='/index' />} />
      <Route path='/login' component={Login} />
      <Route component={DefaultLayout} />
    </Switch>
  </Router>
)

export default App
