
import  { Quill } from 'react-quill';

const BlockEmbed = Quill.import('blots/block/embed')

export class VideoBlot extends BlockEmbed {
  static create(value) {
    let node = super.create()
    node.setAttribute('src', value.url)
    node.setAttribute('controls', value.controls)
    node.setAttribute('width', value.width)
    node.setAttribute('poster', value.poster)
    node.setAttribute('controlslist', 'nodownload noremoteplayback')
    node.setAttribute('oncontextmenu', 'return false')
    return node;
  }
  // 富文本初始化取参数，如果有编辑富文本的功能的话，这段代码就需要加上
  static value(node) {
    return {
      url: node.getAttribute('src'),
      controls: node.getAttribute('controls'),
      width: node.getAttribute('width'),
      poster: node.getAttribute('poster'),
      controlslist: node.getAttribute('controlslist'),
      oncontextmenu: node.getAttribute('oncontextmenu')
    };
  }
}
VideoBlot.blotName = 'video1';
VideoBlot.tagName = 'video';
VideoBlot.className = 'iconfont icon-yonghuguanli';